import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useIntl } from 'react-intl';
import Button from './ui/button';

export default function AboutUsSection({
    description,
}: {
    description: string;
}) {
    const intl = useIntl();
    return (
        <div className="block p-3 lg:p-0 lg:flex flex-wrap bg-gray-50">
            <div className="about__item py-4 lg:py-14 px-0 lg:px-16">
            
                <h2 className="text-2xl lg:text-4xl text-gray-900 font-semibold my-6">
                    {intl.formatMessage({ id: 'whoWeAre' })}
                </h2>
            </div>
            <div className="about__item py-4 lg:py-14 px-0 lg:px-16 mt-6">
                
                
                <p className="text-gray-700">{description}</p>
                
                
            </div>
        </div>
    );
}
