import { graphql } from 'gatsby';
import React from 'react';
import AboutUsSection from 'components/aboutus';
import Layout from 'components/layout';
import Wall from 'components/wall';
import Container from 'components/ui/container';
import { ProductsContainer } from 'components/product';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { useIntl } from 'react-intl';
import WhatWeDo, { Service } from 'components/whatwedo';
// import ContactBanner from 'components/contactbanner';
import { BlogContainer } from 'components/blogitem';

export default function IndexPage({ data }: { data: GatsbyTypes.IndexQuery }) {
    const intl = useIntl();

    const options = data.options?.frontmatter;

    const products = data.products.nodes.map((product) => ({
        id: product.id,
        image: (product.frontmatter?.image?.childImageSharp
            ?.gatsbyImageData as unknown) as IGatsbyImageData,

        slug: product.fields?.slug as string,
        title: product.frontmatter?.title as string,
        category: product.frontmatter?.category as string,
    }));

    const blogItems = data.blog.nodes.map((item) => ({
        id: item.id as string,
        image: (item.frontmatter?.image?.childImageSharp
            ?.gatsbyImageData as unknown) as IGatsbyImageData,

        url: item.fields?.slug as string,
        date: item.frontmatter?.date as string,
        title: item.frontmatter?.title as string,
    }));

    const services = data.options?.frontmatter?.services as Service[];

    const slider =
        options?.slider?.map((item) => ({
            title: item?.title as string,
            description: '',
            buttonText: item?.buttonText as string,
            image: (item?.image?.childImageSharp
                ?.gatsbyImageData as unknown) as IGatsbyImageData,
            action: item?.action as string,
        })) || [];

    return (
        <Layout
            seo={{
                title: intl.formatMessage({
                    id: 'homepageSeoTitle',
                }),
            }}
            isHome={true}
        >
            <Wall data={slider} />
            <AboutUsSection description={options?.aboutUs as string} />
            <section className="products py-6 lg:py-14 p-3 lg:p-0">
                <Container>
                    <h2 className="text-center font-semibold text-2xl lg:text-3xl text-gray-900 py-10 uppercase">
                        {intl.formatMessage({
                            id: 'featuredProducts',
                        })}
                    </h2>
                </Container>
                <ProductsContainer products={products} />
            </section>
            <section className="py-6 lg:py-14 bg-gray-50 p-3 lg:p-0">
                <Container>
                    <h2 className="text-center font-extrabold text-2xl lg:text-4xl text-gray-900 py-10">
                        {intl.formatMessage({
                            id: 'whatWeDo',
                        })}
                    </h2>
                </Container>
                <WhatWeDo data={services} />
            </section>
            <section className="py-6 lg:py-14 p-3 lg:p-0 hidden">
                <Container>
                    <h2 className="text-center font-semibold text-2xl lg:text-4xl text-gray-900 py-10">
                        {intl.formatMessage({
                            id: 'blog',
                        })}
                    </h2>
                </Container>
                <BlogContainer data={blogItems} />
            </section>
        </Layout>
    );
}

export const query = graphql`
    query Index($locale: String!) {
        options: mdx(
            fields: { sourceName: { eq: "options" }, locale: { eq: $locale } }
        ) {
            frontmatter {
                aboutUs
                description
                slogan
                services {
                    title
                    description
                    icon
                    media {
                        type
                        url
                        mediaSrc {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
                slider {
                    image {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    title
                    buttonText
                    action
                }
            }
        }
        products: allMdx(
            filter: {
                fields: {
                    locale: { eq: $locale }
                    sourceName: { eq: "products" }
                }
                frontmatter: { featured: { eq: true } }
            }
            limit: 6
        ) {
            nodes {
                id
                fields {
                    slug
                }
                frontmatter {
                    title
                    category
                    image {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
        blog: allMdx(
            filter: {
                fields: { locale: { eq: $locale }, sourceName: { eq: "blog" } }
            }
            limit: 6
        ) {
            nodes {
                id
                fields {
                    slug
                }
                frontmatter {
                    title
                    date(formatString: "DD MMM, YYYY")
                    image {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
            }
        }
    }
`;
