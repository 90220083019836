import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import Link from 'utils/link';
import Container from './ui/container';

interface BlogItemProps {
    id: string;
    title: string;
    date: string;
    url: string;
    image: IGatsbyImageData;
}

export function BlogItem({ title, date, image, url }: BlogItemProps) {
    return (
        <article className="p-0 lg:p-3 w-full md:w-1/2 lg:w-1/3">
            <Link to={url} title={title} className="block">
                <GatsbyImage image={image} alt={title} />
                <div className="p-3">
                    <p className="text-gray-600">{date}</p>
                    <h3 className="font-semibold text-xl lg:text-2xl text-gray-800 mt-3">
                        {title}
                    </h3>
                </div>
            </Link>
        </article>
    );
}

export function BlogContainer({ data }: { data: BlogItemProps[] }) {
    const items = data.map((item) => <BlogItem {...item} key={item.id} />);
    return (
        <Container>
            <div className="flex flex-wrap">{items}</div>
        </Container>
    );
}
