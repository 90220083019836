import React, { ReactElement, ReactNode } from 'react';
import { Activity } from 'react-feather';
import Container from './ui/container';
import {
    GatsbyImage,
    IGatsbyImageData,
} from 'gatsby-plugin-image';

export type Service = {
    icon: string;
    title: string;
    description: string;
    index: number;
    media: {
        url?: string;
        type?: string;
        mediaSrc?: {
            childImageSharp: { gatsbyImageData: IGatsbyImageData };
        } | null;
    };
};

const icons: Record<string, ReactNode> = {
    activity: <Activity />,
};

export default function WhatWeDo({ data }: { data: Service[] }) {
    const items = data.map((item, i) => (
        <Item
            description={item.description}
            title={item.title}
            icon={icons[item.icon]}
            media={item.media}
            key={`${item.title}-${i}}`}
            index={i}
        />
    ));
    return (
        <Container>
            <div className="flex flex-wrap">{items}</div>
        </Container>
    );
}

const Item = ({
    icon,
    title,
    description,
    media,
    index,
}: Omit<Service, 'icon'> & { icon: ReactNode }) => {
    return (
        <div className="w-full md:w-1/2 lg:w-1/3 p-0 lg:p-3 my-3 lg:my-0">
            <div
                className={`"rounded shadow-lg bg-white hover:shadow-xl hover:-translate-y-2 transform transition-transform duration-300 p-3 py-6 text-center border-t-4 border-stage-${index}`}
            >
                <div className="text-primary flex items-center justify-center py-6">
                    {React.cloneElement(icon as ReactElement, {
                        size: 44,
                    })}
                </div>
                <h3 className="text-2xl text-gray-800 font-semibold my-4">
                    {title}
                </h3>
                <p>{description}</p>
                <div className="mt-6">
                    {media.type === 'embed' && (
                        <iframe
                            className="w-full mb-2" 
                            height="448"
                            src={media.url}
                            title="AMDTEC 12.1inch military display"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    )}
                    {media.type === 'image' && (
                        <GatsbyImage
                            image={
                                media.mediaSrc?.childImageSharp
                                    .gatsbyImageData as IGatsbyImageData
                            }
                            alt="AMDTEC"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
