import React, { ReactNode } from 'react';
import Link from 'utils/link';

export default function Button({
    children,
    buttonProps,
    to,
}: {
    children: ReactNode;
    buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
    to?: string;
}) {
    const className = `${
        buttonProps?.disabled ? 'bg-gray-700' : 'bg-redbg'
    } text-white px-5 py-3 ml-3`;

    if (to) {
        return (
            <Link to={to} className={className}>
                {children}
            </Link>
        );
    }

    return (
        <button className={className} {...buttonProps}>
            {children}
        </button>
    );
}
