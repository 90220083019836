import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
// import { useIntl } from 'react-intl';
import Button from './ui/button';
import { Carousel } from 'react-responsive-carousel';

export interface WallDataProps {
    image: IGatsbyImageData;
    title: string;
    description: string;
    buttonText: string;
    action: string;
}

export default function Wall({ data }: { data: WallDataProps[] }) {
    const items = data.map((item, i) => (
        <CarouselItem {...item} key={item.title + i} />
    ));
    return (
        <Carousel
            className="home-slider"
            autoPlay={true}
            interval={5000}
            infiniteLoop={true}
        >
            {items}
        </Carousel>
    );
}

const CarouselItem = ({ action, description, buttonText, image, title }: WallDataProps) => {
    
    return (
        <div className="relative">
            <GatsbyImage
                image={image}
                alt={title}
                className="w-full h-screen lg:h-screen"
                imgClassName="w-auto h-full lg:w-full h-auto"
            />
            <div className="wall-overlay absolute top-0 left-0 w-full h-full"></div>
            <div className="w-full h-full absolute z-10 top-0 left-0 flex items-center justify-center">
                <div className="max-w-7xl w-full px-6 lg:px-0">
                    <div className="max-w-2xl text-left w-full ml-0 lg:ml-14">
                        <h1 className="text-3xl lg:text-5xl text-gray-50 mb-8 font-semibold main-slogan">
                            {title}
                        </h1>
                        <p className="text-base lg:text-lg text-gray-50 mb-8 pr-0 lg:pr-16">
                            {description}
                        </p>
                        <Button to={action}>
                        {buttonText}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
